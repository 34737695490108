<template>
  <v-main>
    <v-tabs
      v-model="tab"
      class="d-flex justify-center"
    >
      <v-tab class="tab-login">
        Inicia sesión
      </v-tab>
      <!-- <v-tab class="tab-login">
        Regístrate
      </v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <login />
      </v-tab-item>
      <!-- <v-tab-item>
        <registro />
      </v-tab-item> -->
    </v-tabs-items>
  </v-main>
</template>

<script>
  export default {
    name: 'LoginView',
    components: {
      Login: () => import('@/components/auth/Login.vue'),
      // Registro: () => import('@/components/auth/Registro.vue'),
    },
    data () {
      return {
        tab: null,
      }
    },
  }
</script>
<style lang="sass" scoped>
.bg-login
  background-image: url('../../assets/dev/fondo-bg.jpg')
  background-size: cover
  background-position: center
.bg-login-card
  background-image: url('../../assets/dev/fondo-login-card.jpg')
  background-size: cover
  background-position: center
.tab-login
  color: #cacaca !important
  background-color: lightgoldenrodyellow
.tab-login.v-tab--active
  color: #2970e2 !important
  background-color: gainsboro
</style>
